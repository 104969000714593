import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { track } from "../analytics";
import tw, { styled } from "twin.macro";
import Link from "./BaseLink";
import { toPath } from "../utils/paths";
import { SolidButton } from "../utils/helpers";
import JeevzLogo from "../img/jeevz-logo-white.svg";
import Menu from "../img/menu.svg";
import Close from "../img/close.svg";
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql";

const NavWrapper = tw.div`
  block top-0 inset-x-0 flex justify-between md:items-center md:p-6 md:h-auto z-40 lg:px-12 text-white absolute max-w-7xl mx-auto h-20
`;

const Nav = styled.nav`
  ${tw`flex flex-col absolute inset-x-0 top-0 bg-ink z-40 items-start p-12 pt-20 mt-0 transform transition duration-200 ease-in-out opacity-100 md:relative md:flex-row md:p-4 md:mt-0 md:ml-auto md:opacity-100 md:translate-y-0 md:bg-transparent md:shadow-none md:pointer-events-auto md:items-center`}
  ${(props) =>
    props.open
      ? tw`translate-y-0 shadow pointer-events-auto`
      : tw`-translate-y-4 opacity-0 shadow-none pointer-events-none`}
`;

const NavLink = tw(Link)`
  my-2 block px-3 py-2 md:px-6 lg:px-6 md:py-4 md:my-0 rounded-md text-base font-medium focus:outline-none focus:text-gray-900 focus:bg-white transition duration-150 uppercase hover:text-fire ease-in-out tracking-wide
`;

const SubLink = tw(NavLink)`
  md:p-2
`;

const NavGroup = styled.div`
  ${tw`transition duration-200 ease-in-out relative w-auto cursor-pointer md:rounded-lg border-b border-linen md:border-none mb-4 md:m-0`}
  &:hover {
    & > div {
      ${tw`md:opacity-100 md:translate-y-0 md:pointer-events-auto`}
    }
  }
`;

const NavList = tw.div`
  transform transition duration-200 ease-in-out md:absolute md:opacity-0 md:-translate-y-4 md:w-40 md:bg-white md:text-ink md:rounded-lg md:top-12 md:pointer-events-none md:p-2
`;

const GroupLabel = tw(NavLink)`
  relative z-20 text-xs font-normal pb-0 mb-0 md:font-medium md:text-base md:py-4 tracking-widest md:tracking-wide text-linen md:text-white
`;

const LogoWrapper = tw.div`
  flex absolute inset-y-0 z-50 inset-y-0 items-center
`;

const MenuIcon = styled.div`
  ${tw`flex-none py-2 pl-4 cursor-pointer md:hidden`}
  & svg {
    ${tw`w-8 h-8`}
  }
`;

const Logo = styled(Link)`
  ${tw`p-4 flex-none`}
  & svg {
    ${tw`w-24 md:w-32 h-auto`}
  }
`;

const Cta = tw(SolidButton)`
  flex-none z-50 mt-8 md:ml-4 md:mt-0 md:hidden
`;

const Navigation = () => {
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!menu);
  };

  return (
    <NavWrapper>
      <LogoWrapper>
        <MenuIcon onClick={toggleMenu}>{menu ? <Close /> : <Menu />}</MenuIcon>
        <Logo to="/">
          <JeevzLogo />
        </Logo>
      </LogoWrapper>
      <Nav open={menu}>
        <NavLink to="/drivers/">Jeevz Drivers</NavLink>
        <NavLink to="/faq/">FAQ</NavLink>
        <StaticQuery
          query={`${useCasesQuery}`}
          render={withPreview((data) => {
            const useCases = data.prismic.allUse_cases.edges;
            if (!useCases || useCases.length < 1) return null;
            return (
              <NavGroup>
                <GroupLabel>Why Jeevz?</GroupLabel>
                <NavList>
                  {useCases.map(({ node }, idx) => (
                    <SubLink
                      key={`use-case-${idx}`}
                      to={toPath(node._meta.type, node._meta.uid)}
                    >
                      {RichText.asText(node.page_title)}
                    </SubLink>
                  ))}
                </NavList>
              </NavGroup>
            );
          }, useCasesQuery)}
        />
        <NavLink to="/about/">About</NavLink>
        <Cta
          white={1}
          to="http://onelink.to/rutnj9"
          onClick={() =>
            track("Download button clicked", {
              location: "navigation",
            })
          }
        >
          Download Now
        </Cta>
      </Nav>
    </NavWrapper>
  );
};

export default Navigation;

const useCasesQuery = graphql`
  query {
    prismic {
      allUse_cases(where: { live: true }) {
        edges {
          node {
            page_title
            _meta {
              uid
              type
            }
          }
        }
      }
    }
  }
`;
